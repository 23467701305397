const autoNgTemplateLoaderTemplate1 = require('./helm-templates.html');

import angular from 'angular';
import controller from './helm-templates.controller';

angular.module('portainer.kubernetes').component('helmTemplatesView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    endpoint: '<',
  },
});
