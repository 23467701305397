const autoNgTemplateLoaderTemplate1 = require('./associatedEndpointsDatatable.html');

import angular from 'angular';

import { AssociatedEndpointsDatatableController } from './associatedEndpointsDatatableController';

angular.module('portainer.edge').component('associatedEndpointsDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: AssociatedEndpointsDatatableController,
  bindings: {
    titleText: '@',
    titleIcon: '@',
    tableKey: '@',
    orderBy: '@',
    reverseOrder: '<',
    retrievePage: '<',
    updateKey: '<',
  },
});
