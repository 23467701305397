const autoNgTemplateLoaderTemplate1 = require('./containerCapabilities.html');

import controller from './container-capabilities.controller';

angular.module('portainer.docker').component('containerCapabilities', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    capabilities: '=',
  },
  controller,
});
