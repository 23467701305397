const autoNgTemplateLoaderTemplate1 = require('./nodesDatatable.html');

angular.module('portainer.docker').component('nodesDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'GenericDatatableController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    tableKey: '@',
    orderBy: '@',
    reverseOrder: '<',
    showIpAddressColumn: '<',
    accessToNodeDetails: '<',
    refreshCallback: '<',
  },
});
