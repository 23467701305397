const autoNgTemplateLoaderTemplate1 = require('./helm-add-repository.html');

import angular from 'angular';
import controller from './helm-add-repository.controller';

angular.module('portainer.kubernetes').component('helmAddRepository', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    repos: '<',
    endpoint: '<',
  },
});
