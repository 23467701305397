const autoNgTemplateLoaderTemplate1 = require('./containersDatatable.html');

angular.module('portainer.kubernetes').component('kubernetesContainersDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'GenericDatatableController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    tableKey: '@',
    orderBy: '@',
    refreshCallback: '<',
    isPod: '<',
    useServerMetrics: '<',
  },
});
