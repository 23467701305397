const autoNgTemplateLoaderTemplate1 = require('./kubernetes-redeploy-app-git-form.html');

import angular from 'angular';
import controller from './kubernetes-redeploy-app-git-form.controller';

const kubernetesRedeployAppGitForm = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    stack: '<',
    namespace: '<',
  },
};

angular.module('portainer.app').component('kubernetesRedeployAppGitForm', kubernetesRedeployAppGitForm);
