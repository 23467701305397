const autoNgTemplateLoaderTemplate1 = require('./nomadLogViewer.html');

import controller from './nomadLogViewerController';

export const nomadLogViewer = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    stderrLog: '<',
    stdoutLog: '<',
    resourceName: '<',
    logCollectionChange: '<',
  },
};
