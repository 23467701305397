const autoNgTemplateLoaderTemplate1 = require('./edgeGroupsView.html');

import angular from 'angular';

import { EdgeGroupsController } from './edgeGroupsViewController';

angular.module('portainer.edge').component('edgeGroupsView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: EdgeGroupsController,
});
