const autoNgTemplateLoaderTemplate1 = require('./createResourcePool.html');

import angular from 'angular';
import KubernetesCreateResourcePoolController from './createResourcePoolController';

angular.module('portainer.kubernetes').component('kubernetesCreateResourcePoolView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: KubernetesCreateResourcePoolController,
  bindings: {
    endpoint: '<',
  },
});
