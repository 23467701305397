const autoNgTemplateLoaderTemplate1 = require('./templateList.html');

angular.module('portainer.app').component('templateList', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'TemplateListController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    templates: '<',
    tableKey: '@',
    selectAction: '<',
    showSwarmStacks: '<',
  },
});
