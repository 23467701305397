const autoNgTemplateLoaderTemplate1 = require('./servicesDatatableActions.html');

angular.module('portainer.docker').component('servicesDatatableActions', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'ServicesDatatableActionsController',
  bindings: {
    selectedItems: '=',
    selectedItemCount: '=',
    showUpdateAction: '<',
    showAddAction: '<',
    endpointId: '<',
  },
});
