const autoNgTemplateLoaderTemplate1 = require('./application.html');

angular.module('portainer.kubernetes').component('kubernetesApplicationView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesApplicationController',
  controllerAs: 'ctrl',
  bindings: {
    $transition$: '<',
    endpoint: '<',
  },
});
